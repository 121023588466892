@font-face {
  font-family: 'proxima-nova';
  font-weight: 300;
  font-style: normal;
  src: url('./proxima-nova/300-proxima-nova-light.woff2') format('woff2'),
    url('./proxima-nova/300-proxima-nova-light.woff') format('woff');
}
@font-face {
  font-family: 'proxima-nova';
  font-weight: 400;
  font-style: normal;
  src: url('./proxima-nova/400-proxima-nova-regular.woff2') format('woff2'),
    url('./proxima-nova/400-proxima-nova-regular.woff') format('woff');
}
@font-face {
  font-family: 'proxima-nova';
  font-weight: 600;
  font-style: normal;
  src: url('./proxima-nova/600-proxima-nova-semibold.woff2') format('woff2'),
    url('./proxima-nova/600-proxima-nova-semibold.woff') format('woff');
}
@font-face {
  font-family: 'proxima-nova';
  font-weight: 700;
  font-style: normal;
  src: url('./proxima-nova/700-proxima-nova-bold.woff2') format('woff2'),
    url('./proxima-nova/700-proxima-nova-bold.woff') format('woff');
}
